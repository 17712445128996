



















































































import Vue from 'vue';
import TextField from '../components/TextField.vue';
import { Product } from '../../../shared/src/entities/Product';
import ConfirmDialog from '../components/ConfirmDialog.vue';
import ListSelectorField from '../components/ListSelectorField.vue';
import ImageUpload from '../components/ImageUpload.vue';
import TextArea from '../components/TextArea.vue';
import { Category } from '../../../shared/src/entities/Category';
import { IProductDataservice } from '../../../shared/src/dataservices/IProductDataservice';
import { ImageRef } from '../../../shared/src/entities/ImageRef';
import { ProductStatus } from '../../../shared/src/entities/ProductStatus';
import { ICategoryDataservice } from '../../../shared/src/dataservices/ICategoryDataservice';

export default Vue.extend({
  components: {
    TextField,
    ConfirmDialog,
    ListSelectorField,
    ImageUpload,
    TextArea
  },
  inject: {
    productDataservice: 'productDataservice',
    uuidHelperService: 'uuidHelperService',
    imageDataservice: 'imageDataservice',
    categoryDataservice: 'categoryDataservice'
  },
  data() {
    return {
      imageLoading: false as boolean,
      imageSlots: [
        { index: 0, uploading: false, imageUrl: undefined as undefined | string},
        { index: 1, uploading: false, imageUrl: undefined as undefined | string},
        { index: 2, uploading: false, imageUrl: undefined as undefined | string}
      ]
    };
  },
  computed: {
    categories(): Category[] {
      return ((this as any).categoryDataservice as ICategoryDataservice).categories;
    },
    products(): Product[] {
      return ((this as any).productDataservice as IProductDataservice).products;
    },
    productId(): string {
      return this.$route.params.id;
    },
    product(): Product | undefined {
      if (!this.$route.params.id) {
        return undefined;
      }

      return this.products.find((p) => p.id === this.$route.params.id);
    },
    selectedCategory(): Category | undefined {
      if (!this.product) {
          throw new Error('Failed getting product category, product was undefined, productid: ' + this.productId);
      }

      const categoryId = this.product.categoryId;

      return this.categories.find( (category) => category.id === categoryId);
    },
    allProductStatusValues(): ProductStatus[] {
      return ProductStatus.all();
    },
    selectedStatus(): ProductStatus {
      if (!this.product) {
          throw new Error('Failed getting product status, product was undefined, productid: ' + this.productId);
      }
      return this.product.status;
    },
    breadcrumbs(): any[] {
        return [
          {
            text: 'Produkter',
            disabled: false,
            to: {name: 'Products'},
            exact: true
          },
          {
            text: this.productId,
            disabled: false,
            exact: true
          }
        ];
    }
  },
  watch: {
    product: {
      immediate: true,
      handler(newValue: Product): void {
        this.imageSlots[0].imageUrl = this.getImageDownloadUrl(0);
        this.imageSlots[1].imageUrl = this.getImageDownloadUrl(1);
        this.imageSlots[2].imageUrl = this.getImageDownloadUrl(2);
      }
    }
  },
  methods: {
      getImageDownloadUrl(imageSlotIndex: number): string | undefined {
        if (!this.product || !this.product.images || !this.product.images.get(imageSlotIndex)) {
          return undefined;
        }

        const image = this.getImage(imageSlotIndex);

        if (!image) {
          return undefined;
        }

        return image.mediumImagedownloadUrl;
      },
      getImage(imageSlotIndex: number): ImageRef | undefined {

        if (!this.product) {
          return undefined;
        }

        const image = this.product.images.get(imageSlotIndex);

        if (!image) {
          return undefined;
        }

        return image;
      },
      productnameUpdated(newName: string): void {
        if (!this.product) {
          throw new Error('Failed updating name on product but product was undefined, productid: ' + this.productId);
        }

        ((this as any).productDataservice).updateProductName(this.product.id, newName);
      },
      productPriceUpdated(newPrice: number): void {
        if (!this.product) {
          throw new Error('Failed updating price on product but product was undefined, productid: ' + this.productId);
        }

        ((this as any).productDataservice).updateProductPrice(this.product.id, newPrice);

      },
      productDescriptionUpdated(description: string): void {
        if (!this.product) {
          throw new Error('Failed updating description but product was undefined, productid: ' + this.productId);
        }

        ((this as any).productDataservice as IProductDataservice).updateProductDescription(this.product.id, description);
      },
      imageUpdated(image: File, imageSlotIndex: number): void {
        if (!this.product) {
          throw new Error('Failed updating image but product was undefined, productid: ' + this.productId);
        }

        this.imageSlots[imageSlotIndex].uploading = true;
        this.imageLoading = true;
        ((this as any).productDataservice as IProductDataservice).updateProductImage(this.product.id, image, imageSlotIndex).then(() => {
          this.imageSlots[imageSlotIndex].uploading = false;
        });
      },
      imageDeleted(imageSlotIndex: number): void {
        if (!this.product) {
          throw new Error('Cant delete product as it is undefined, productid: ' + this.productId);
        }

        ((this as any).productDataservice as IProductDataservice).deleteProductImageByIndex(this.product, imageSlotIndex);

      },
      categoryUpdated(newCategory: Category): void {
        if (!this.product) {
          throw new Error('Failed updating category on product but product was undefined');
        }

        if (newCategory) {
          ((this as any).productDataservice as IProductDataservice).updateCategory(this.product.id, newCategory.id);
        } else {
          ((this as any).productDataservice as IProductDataservice).updateCategory(this.product.id, undefined);
        }
      },
      deleteProduct(): void {
        (this.$refs.confirmDialog as any).show('Er du sikker på at du vil slette produktet?', this.acceptDeleteProduct);
      },
      acceptDeleteProduct(): void {
        if (!this.product) {
          return;
        }
        ((this as any).productDataservice as IProductDataservice).deleteProduct(this.product.id).then(() => {
          this.$router.go(-1);
        });
      },
      saveProduct(): void {
        if (!this.product) {
          return;
        }

        (this.$refs.productNameComponent as any).save();
        (this.$refs.priceComponent as any).save();
        (this.$refs.categoryComponent as any).save();
        (this.$refs.statusComponent as any).save();
        (this.$refs.descriptionComponent as any).save();
        this.$router.go(-1);
      },
      statusUpdated(newStatus: ProductStatus): void {
        if (!this.product) {
          return;
        }

        ((this as any).productDataservice as IProductDataservice).updateStatus(this.product.id, newStatus);
      }
  }
});
